import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import "./font.css";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { Div, StyleReset, ThemeProvider } from "atomize";
import AuthProvider from "../context/AuthContext";
import { QueryClientProvider } from "react-query";
import { queryClient } from "../lib/queryClient";

const theme = {
  fontFamily: {
    primary:
      '"SF Pro Text",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    secondary:
      '"SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    code: "'Fira Mono','Roboto Mono', monospace",
  },
  grid: {
    containerMaxWidth: {
      xl: "1184px",
    },
  },
  textSize: {
    size: {
      display3: "48px",
    },
    height: {
      display3: "56px",
    },
  },
  colors: {
    brandgray: "#F7F5F4",
  },
};

const Layout = ({ children, hasLoader = true }) => {
  const [isShowingConfetti, setIsShowingConfetti] = useState(false);
  const { width, height } = useWindowSize();
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <AuthProvider hasLoader={hasLoader}>
          <ThemeProvider theme={theme}>
            {isShowingConfetti ? (
              <Confetti width={width} height={height * 2} />
            ) : null}
            <StyleReset />
            <Toaster />
            <Div tag="main" overflow="hidden">
              {typeof children === "function"
                ? children({ setIsShowingConfetti, isShowingConfetti })
                : children}
            </Div>
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </div>
  );
};

export default Layout;
