import React, { createContext, useEffect, useMemo, useState } from "react";
import { getUser, logout as logoutLg, setUser } from "../utils/auth";
import { client } from "../lib/client";

export const AuthContext = createContext({});

const AuthProvider = ({ children, hasLoader = true }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const logout = () => {
    window.location.reload();
    logoutLg();
  };

  useEffect(() => {
    const userFromLg = getUser();
    if (userFromLg) {
      setUser(userFromLg);
      client
        .get("users/me")
        .then((res) => {
          const user = res.data?.data;
          setUser({ ...userFromLg, ...user });
          setIsLoggedIn(true);
        })
        .catch(() => {
          logout();
          setIsLoggedIn(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const login = (user, onLogin) => {
    setUser(user);
    if (onLogin) {
      onLogin();
    }
  };

  const authContext = useMemo(
    () => ({
      login,
      logout,
      isLoggedIn,
    }),
    [isLoggedIn]
  );

  if (isLoading && hasLoader) {
    return <>Loading...</>;
  }

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
