import { client } from "../lib/client";

export const isBrowser = () => typeof window !== "undefined";

export const AUTH_KEY_LS = "@filehire/user";

export const getUser = () => {
  return isBrowser() && window.localStorage.getItem(AUTH_KEY_LS)
    ? JSON.parse(window.localStorage.getItem(AUTH_KEY_LS))
    : null;
};

export const setUser = (user) => {
  client.defaults.headers.Authorization = `Bearer ${user?.token ?? ""} `;
  window.localStorage.setItem(AUTH_KEY_LS, JSON.stringify(user));
};

export const handleLogin = ({ username, password }) => {
  if (username === `john` && password === `pass`) {
    return setUser({
      username: `john`,
      name: `Johnny`,
      email: `johnny@example.org`,
    });
  }

  return false;
};

export const isLoggedIn = () => {
  const user = getUser();

  return !!user;
};

export const logout = (callback) => {
  setUser(null);
  if (callback) {
    callback();
  }
};
