import axios from "axios";
import { getUser } from "../utils/auth";

const API_URL = process.env.GATSBY_API_URL || "http://127.0.0.1:3001/";

export const client = axios.create({
  baseURL: API_URL,

  headers: {
    // Authorization: `Bearer ${getUser()?.token ?? ""}`,
  },
});
